module.exports = {
	footerText: 'All rights reserved by - Yotta ERP',

	apiserver: 'https://supportapi.yottaerp.com/api/',


	// apiserver: 'https://fmsapi.yottaerp.com/api/',

	profile_pic_url: 'https://supportapi.yottaerp.com/public/upload/profile/',

	// detailsImgURL: 'https://fmsapi.yottaerp.com/public/upload/support_article/',
	detailsImgURL: 'https://supportapi.yottaerp.com/public/upload/support_article/',
	
	logourl : '/assets/images/logo.png',
	print_logourl : '/assets/images/yotta_logo.png',
	company_name : 'Yotta ERP',
	address : 'Block-A, Lalmatia',
	city : 'Dhaka',
	state : 'Dhaka',
	zipcode : '1207',
	phone : "",
	email : "info@yottaerp.com",
	website : "https://yottaerp.org"
}