import React from 'react'

const Loading = () => {
    return (
        <div className='py-5' style={{height:"100vh",textAlign:"center"}}>
            <img src="/images/yotta-icon.png" alt="Logo" style={{width:"80px"}}/>
            
        </div>
    )
}

export default Loading
